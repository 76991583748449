import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Store } from 'app/core/services/store/store';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { escape, throttle, debounce } from 'lodash';
import { first, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import './datav2';

enum Role {
  User = 'user',
  Moderator = 'moderator',
}

import {
  ChatParticipant,
  Message,
  Participant,
  Powers,
  Report,
  Review,
  RoomDocument,
} from 'app/shared/interfaces/room';
import { FirebaseService } from 'app/core/services/firebase/firebase.service';
import { HttpClient } from '@angular/common/http';

enum PowerType {
  Mute = 'Mute',
  KickOut = 'Kick out',
  Emergency = 'Emergency',
}

const MODERATOR_TIMEOUT = 500;

/**
 * Helper for the chat page
 */
@Injectable({
  providedIn: 'root',
})
export class ChatRoomRealtimeService {
  public active: boolean;
  public waitingForMessage = false;
  public lastMessageFromModerator = false;
  // fresh list is here, you don't need to make additional queries
  public allUsers: { [key: string]: Participant };
  public currentParticipant: any;
  public activeUsers: ChatParticipant[] = [];
  public usersMap: { [key: string]: ChatParticipant } = {};
  public roomDoc: AngularFirestoreDocument;
  public messagesCol: AngularFirestoreCollection;
  public moderatorName: string;
  public moderatorEmail: string;
  public messages$: Observable<Message[]>;

  private roomsCollection: AngularFirestoreCollection<RoomDocument>;
  private colorIndex = 0;
  private readonly currentUserColor = '#684c9b';
  private readonly moderatorColor = '#0089b0';
  private readonly modChatBotInitialMsgCount = 10;
  private readonly defaultLimit = 70;
  private roomid;

  public users = [(window as any).currentUser.display_name];
  public typingUsers = [];
  private readonly colors = [
    '#70846d',
    '#e4873c',
    '#c8b833',
    '#d94238',
    '#5c9ed5',
    '#7470ad',
    '#b18059',
    '#7c7b8c',
    '#87a55a',
    '#ad5b57',
    '#6b8eaa',
  ];

  constructor(
    private afs: AngularFirestore,
    private firebaseService: FirebaseService,
    private http: HttpClient,
    private store: Store,
  ) {
    const roomid = this.afs.createId();
    const win = window as any;
    this.roomid = roomid;

    this.roomsCollection = afs.collection('moderatorScreening');
    this.roomDoc = this.roomsCollection.doc(roomid);
    this.roomDoc.set({
      userId: win.currentUser.ID,
      displayName: win.currentUser.display_name,
      createdAt: this.firebaseService.firestore.FieldValue.serverTimestamp(),
      userLogin: win.currentUser.user_login,
    });
    this.messagesCol = this.roomDoc.collection('messages');

    this.init();
  }

  public botMessages = [...(window as any).csvData];

  public awaitMessage = debounce(() => {
    if (this.waitingForMessage && this.lastMessageFromModerator) {
      this.sendBotMessage();
    }
  }, MODERATOR_TIMEOUT);

  /**
   * Init method to have single-tone like service
   */
  public init(): void {
    this.colorIndex = 0;
    const [moderatorFirstName, moderatorLastName] = (window as any).currentUser.display_name;
    let thought = '';

    try {
      thought = this.botMessages[2][4].match(/"(\D+)/)[1].replace('"', '');
    } catch (e) {}

    this.handleRoomUpdate({
      active: true,
      modWithPushNotification: true,
      moderatorEmail: '',
      userIDs: ['user'],
      moderatorFirstName,
      moderatorLastName,
      moderatorScreenName: (window as any).currentUser.display_name,
      leave: false,
      moderator: {
        id: {
          id: (window as any).currentUser.ID,
        },
      },
      users: {
        user: {
          id: { id: 'user' },
          active: true,
          from: 'chatbot',
          joinedAt: Date.now(),
          thought,
          isChatting: false,
          screenName: this.botMessages[2][2],
        },
      },
      isChatting: false,
    } as any);

    this.messages$ = this.roomDoc
      .collection('messages', (ref) => ref.orderBy('createdAt', 'asc'))
      .valueChanges() as Observable<Message[]>;
    // .pipe(map(messages => messages.reverse()));
    // this.messages$ = this.limit$.pipe(
    //   switchMap(limit => {
    //     const messages$ = this.roomsCollection
    //       .doc(roomId)
    //       .collection('messages', ref => ref.orderBy('createdAt', 'desc').limit(limit))
    //       .valueChanges()
    //       .pipe(map(messages => messages.reverse()));
    //
    //     return combineLatest(messages$, reports$);
    //   }),
    //   mergeMap(this.mapMessages.bind(this)),
    //   tap(this.ratingWatcher.bind(this)),
    // ) as Observable<Message[]>;

    this.botMessages.splice(0, 2);
    this.sendBotMessage();

    // could we add a timer for 4 minutes or time out feature at the 4 minute mark?
    setTimeout(async () => {
      this.botMessages = [];

      const messages = await this.roomDoc
        .collection('messages', (ref) => ref.orderBy('createdAt', 'asc'))
        .valueChanges()
        .pipe(first())
        .toPromise();

      await this.http.post('/api/chat-end', { messages, user: this.store.state }).toPromise();
    }, 4 * 60 * 1000);
  }

  getUserColor(message): string {
    return message.role === Role.User ? this.colors[this.users.indexOf(message.screenName)] : this.moderatorColor;
  }

  getScreenName(message): string {
    return message.role === Role.User ? message.screenName : this.moderatorName;
  }

  /**
   * If a moderator puts a user on mute, then, along with the usual field (createdAt, id, msg, role),
   * we add a field in the moderator message document: powers. We do not update the message document,
   * but push the powers field along with the others. This field allows us to tell when and why a moderator
   * put a user to mute.
   */
  async toggleMute(user: string): Promise<any> {
    this.sendMessage(`${user} is in "time out" to review rules.`);
  }

  /**
   * Kickout user from the chat
   */
  async kickOut(user: string): Promise<any> {
    this.sendMessage(`${user} is suspended for misbehavior.`);
  }

  /**
   * Kickout user from the chat and redirect to the emergency screen
   */
  async showEmergencyPage(user: string): Promise<any> {
    this.sendMessage(`${user} is receiving individual attention.`);
  }

  /**
   * Send message to the room
   * Pass { system: true } if you want to send system message without user name
   */
  public sendMessage(msg: string) {
    this.messagesCol.add({
      msg: msg,
      screenName: this.moderatorName,
      createdAt: this.firebaseService.firestore.FieldValue.serverTimestamp(),
      role: Role.Moderator,
    });

    this.lastMessageFromModerator = true;
    // this.currentParticipant.isChatting = true;
    this.activeUsers = [...this.activeUsers];
    this.awaitMessage();

    setTimeout(() => {
      const textarea = document.querySelector('textarea');
      if (textarea && textarea.focus) {
        textarea.focus();
      }
    }, 1000);
  }

  public async sendBotMessage() {
    this.waitingForMessage = false;

    while (this.botMessages.length) {
      const [, , screenName, role, msg] = this.botMessages.shift();

      if (role !== Role.User) {
        continue;
      }

      if (!this.users.includes(screenName)) {
        this.users.push(screenName);
      }

      this.typingUsers = [screenName];
      // this.activeUsers = [...this.activeUsers];

      setTimeout(() => {
        // this.currentParticipant.isChatting = false;
        this.waitingForMessage = true;
        this.lastMessageFromModerator = false;

        this.typingUsers = [];
        // this.activeUsers = [...this.activeUsers];
        this.messagesCol.add({
          screenName,
          msg: escape(msg),
          createdAt: this.firebaseService.firestore.FieldValue.serverTimestamp(),
          role,
        });

        if (this.botMessages[0] && this.botMessages[0][3] === Role.User) {
          this.sendBotMessage();
        }
      }, 300);

      break;
    }

    if (!this.botMessages.length) {
      setTimeout(async () => {
        // const win = window as any;

        const messages = await this.roomDoc
          .collection('messages', (ref) => ref.orderBy('createdAt', 'asc'))
          .valueChanges()
          .pipe(first())
          .toPromise();

        await this.http.post('/api/chat/end', { messages, user: this.store.state }).toPromise();

        //   const messagesTbody = messages.map(message => {
        //     return `<tr style="background: ${message.role === 'moderator' ? '#dfdfdf' : 'white'}">
        //     <td>${message.screenName}</td>
        //     <td>${message.role}</td>
        //     <td>${message.msg}</td>
        //     <td>${message.createdAt ? new Date(message.createdAt.seconds * 1000).toUTCString() : '-'}</td>
        //     </tr>`;
        //   });
        //
        //   const table = `<table cellpadding="5" cellspacing="0" border="1">
        // <tr>
        //   <th>Screen Name</th>
        //   <th>Role</th>
        //   <th>Message</th>
        //   <th>Date</th>
        // </tr>
        //   ${messagesTbody.join('')}
        // </table>`;
        //
        //   const html = `
        //   <div><strong>User Id: ${win.currentUser.ID}</strong></div>
        //   <div><strong>Display Name: ${win.currentUser.display_name}</strong></div>
        //   <div><strong>Room id: ${this.roomid}</strong></div>
        //   <div><strong>User Login: ${win.currentUser.user_login}</strong></div>
        //   <div><strong>Chatbot type: with local messages</strong></div>
        //
        //   <h4>Messages</h4>
        //
        //   ${table}
        // `;
        //
        //   await this.http
        //     .post('https://supportiv-stage-webapp-api.uc.r.appspot.com/api/public/sendmail', {
        //       html,
        //       from: 'auto@supportiv.com',
        //       to: 'moderator@supportiv.com',
        //       subject: `Moderator ${win.currentUser.user_login} finished chat`,
        //     })
        //     .toPromise();
      }, 2000);
    }
  }

  /**
   * Check if message of current user
   */
  public isMineMessage(message: Message): boolean {
    return message.role === Role.Moderator;
  }

  /**
   * Keeps data up to date with structure needed for the chat page
   */
  public handleRoomUpdate = async (room: RoomDocument) => {
    const { users } = room;
    const userIds = Object.keys(users).sort((keyA) => (users[keyA].active ? -1 : 1));
    this.allUsers = room.users;
    this.active = room.active;

    this.updateModeratorInfo(room);

    for (const roomUserId of userIds) {
      const roomUser = users[roomUserId];
      const userId = roomUser.id.id;
      const userInfo = {
        joinedAt: roomUser.joinedAt,
        hasMsgRating: roomUser.hasMsgRating,
        sentRating: roomUser.sentRating,
        active: roomUser.active,
        powers: roomUser.powers || {},
        isChatting: roomUser.isChatting,
      };

      // get user from the users collection
      const color = this.colors[0];

      this.usersMap[userId] = {
        ...userInfo,
        roomUserId,
        color,
        me: false,
        index: 0,
        isModerator: false,
        avatar: null,
        screenName: roomUser.screenName,
        shortName: roomUser.screenName.substr(0, 1).toUpperCase(),
        thought: roomUser.thought,
        active: roomUser.active,
        powers: roomUser.powers || {},
        isChatting: roomUser.isChatting,
      };

      this.currentParticipant = this.usersMap[userId];
    }

    this.activeUsers = Object.values(this.usersMap);
  };

  public async deleteMessage(message: Message) {
    const [messageMeta] = await this.roomDoc
      .collection('messages', (ref) =>
        ref
          .where('screenName', '==', (message as any).screenName)
          .where('msg', '==', message.msg)
          .limit(1),
      )
      .snapshotChanges()
      .pipe(first())
      .toPromise();

    return this.roomDoc.collection('messages').doc(messageMeta.payload.doc.id).update({
      deletedAt: this.firebaseService.firestore.FieldValue.serverTimestamp(),
    });
  }

  /**
   * Refreshes the moderator info
   */
  public async updateModeratorInfo(room: RoomDocument) {
    const { moderator, isChatting } = room;

    // if (this.usersMap[moderator.id]) {
    //   // Update only is chatting field
    //   return Object.assign(this.usersMap[moderator.id], {
    //     isChatting,
    //   });
    // }

    this.moderatorName = room.moderatorScreenName;
    this.moderatorEmail = room.moderatorEmail;
    this.usersMap[moderator.id] = {
      me: true,
      avatar: null,
      isChatting: false,
      index: 0,
      isModerator: true,
      screenName: 'Me',
      shortName: 'Me',
      active: true,
      color: this.moderatorColor,
    };
  }
}
