import { Component, OnInit, OnDestroy, AfterViewChecked, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Store } from 'app/core/services/store/store';
import { trim, throttle } from 'lodash';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { ToastrService } from 'ngx-toastr';

import { LoadingSpinnerService } from 'app/core/services/loading-spinner/loading-spinner.service';
import { ChatRoomRealtimeService } from 'app/shared/services/chat-room-realtime/chat-room-realtime.service';
import { Message } from 'app/shared/interfaces/room';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, AfterViewChecked {
  constructor(
    public chat: ChatRoomRealtimeService,
    private loadingSpinner: LoadingSpinnerService,
    private modalService: NgbModal,
    // private toastr: ToastrService,
    private store: Store,
  ) {}
  public isModerator = true;
  public message = '';
  public isChatting = false;
  public roomId: string;
  public messages: Message[];
  public messageToDelete: Message | null;
  public showAutoScroll: boolean;

  private prevScrollHeight: number;
  private forceScrollToBottom = true;
  private messagesSubscription: Subscription;
  private readonly autoScrollOffset = 250;

  @ViewChild('scrollArea') private scrollContainer: ElementRef;
  @ViewChild('minutes') minutesModalContent: ElementRef;

  ngOnInit() {
    this.messagesSubscription = this.chat.messages$.subscribe((messages) => {
      this.messages = messages;

      setTimeout(() => {
        if (this.scrollContainer && this.prevScrollHeight) {
          const { nativeElement } = this.scrollContainer;

          if (nativeElement.scrollHeight !== this.prevScrollHeight) {
            nativeElement.scrollTop = nativeElement.scrollHeight - this.prevScrollHeight;
          }

          this.prevScrollHeight = null;
        } else {
          this.scrollToBottom();
        }
      }, 50);
    });
  }

  ngAfterViewChecked() {
    if (this.forceScrollToBottom) {
      this.scrollToBottom(true);
    }
  }

  handleTyping = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.sendMessage();
    }

    this.chat.awaitMessage();
  };

  handleScroll() {
    const {
      nativeElement: { scrollTop, offsetHeight, scrollHeight },
    } = this.scrollContainer;

    this.showAutoScroll = scrollHeight - (offsetHeight + scrollTop) > this.autoScrollOffset;
    this.forceScrollToBottom = false;
  }

  scrollToBottom(force = false) {
    try {
      const {
        nativeElement: { scrollTop, offsetHeight, scrollHeight },
      } = this.scrollContainer;

      if (force) {
        this.scrollContainer.nativeElement.scrollTop = scrollHeight;
      } else if (scrollHeight - (offsetHeight + scrollTop) < this.autoScrollOffset) {
        this.scrollContainer.nativeElement.scrollTop = scrollHeight;
      }
    } catch (err) {}
  }

  async sendMessage() {
    if (trim(this.message)) {
      this.chat.sendMessage(this.message);
      this.message = '';
    }

    this.forceScrollToBottom = true;
  }

  confirmDeleteMessage(message: Message) {
    this.messageToDelete = message;
  }

  dismissDeleteMessage() {
    this.messageToDelete = null;
  }

  deleteMessage() {
    this.chat.deleteMessage(this.messageToDelete);
    this.messageToDelete = null;
    // this.toastr.success('Message was successfully deleted');
  }

  trackByMessage(index, message: Message) {
    return message.createdAt + message.msg;
  }

  notifyAboutExitModerator() {}

  openModal(content) {
    this.modalService.open(content, { windowClass: 'w-340' });
  }
}
