import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  public spinnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * To show spinner
   */
  show() {
    this.spinnerSubject.next(true);
  }

  /**
   * To hide spinner
   */
  hide() {
    this.spinnerSubject.next(false);
  }
}
