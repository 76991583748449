import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LoginModule } from 'app/modules/login/login.module';
// import { NgxWebstorageModule } from 'ngx-webstorage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { APP_ERROR_PROVIDER } from './app-error-handler';
// import { ToastrModule } from 'ngx-toastr';
import { ChatModule } from 'app/modules/chat/chat.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    // ToastrModule.forRoot(),
    BrowserModule,
    CoreModule,
    ChatModule,
    LoginModule,
    SharedModule,
    // NgxWebstorageModule.forRoot(),
  ],
  providers: [APP_ERROR_PROVIDER],
  bootstrap: [AppComponent],
})
export class AppModule {}
