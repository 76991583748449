import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
})
export class TermsModalComponent implements OnInit {
  @ViewChild('terms') private termsModal: ElementRef;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  show() {
    this.modalService.open(this.termsModal);
  }
}
