// (window as any).currentUser = { ID: '012', user_login: 'stas@supportiv.com', display_name: 'stas' };
const entries = [
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I just am so tired of life`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I want to kill myself`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I cut sometimes`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I hate being alive`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I am so tired of my kids.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I hate my husband`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My wife is so annoying.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My wife complains so much, I can't do anything right.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `Everything is going wrong.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `There's no point to living anymore`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I just can't seem to save any money`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I struggle so much with my budget, we can barely afford food.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I want to put my baby up for adoption, but I am so scared.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I think I am pregnant, I am only 17.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I just got diagnosed with autism, I've been struggling for so long.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `Nothing is making sense anymore, I miss her so much.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I think I am gay, but my family is so religious.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I hooked up with my ex last night.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I think I have an eating disorder.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I haven't eaten in days, I just want to be skinny.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I can't stop eating and feel so ashamed.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My boss is being so mean to me.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I can't stand my job but I can't quit.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I am so unhappy all the time.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I feel depressed.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I am scared to go outside.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My agoraphobia has gotten so bad, I never leave the house.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I am terrifed of dating and getting hurt again.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `Everything feels so scary`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I just don't feel good enough.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I get so angry I punch walls.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My dog just died, I have never cried so much, this is horrible`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I just can't stop crying.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `Everything feels worse everyday, soon I won't be able to walk.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I got in a bad accident and my entire life changed.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I suffer from migraines they're so bad`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I got in a car accident yesterday.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I think I am starting to lose my hair.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I am thinking about quitting my job.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I love my job but I might need to move for my son.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My parents are so mad at me, I don't know what to do.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I feel so happy when I'm alone, but I don't want to break up.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I think I might be autistic.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My kids are so annoying sometimes.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `Music makes me happy but that's it.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `Nothing makes me happy anymore.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I keep losing weight, I think something is wrong with me.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I don't feel fulfilled anymore and I don't know what to do.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I used to love my job but now I hate it.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `Everything seems so repetitive, will I just work and work until I die`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I started working out but I still hate my body.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I have court next week and I am so stressed`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My husband just filed for divorce.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I think my boyfriend is going to break up with me.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I think my girlfriend is cheating.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I caught my cousin sneaking out.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I caught my boyfriend cheating.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I broke my ankle and I'm supposed to lead our track team tomorrow.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `All I do is watch Netflix and cry.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I can barely afford bread with inflation.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `Our house burned down yesterday I am broken`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I can barely save $100 a week, there's no way out of this debt.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My teenage son was just diagnosed with schizophrenia.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I keep hearing voices that are telling me strange things.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I barely have energy to shower, I just want to give up.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My husband passed away this week, I feel so alone.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I just want to be a singer but I have to work for my children.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I don't even know what my passions are anymore.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I feel so defeated everything I try to do seems to fail.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My parents used to beat me now I live in foster care.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I just really hate myself.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I tried to make soup but spilled it, I'm not good at anything.`],
  [
    '2022-12-30',
    '12:00:00',
    'Alexis',
    'user',
    `I keep trying to eat healthy but it's hard with how much vegetables cost.`,
  ],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I am exhausted fighting against my parents everyday.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I think I got sexually assaulted.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I am failing all of my college classes.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I want to drop out of college`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I just hate going to church every week, I am gay but no one knows.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I am in love with my best friend, but I cannot tell them.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My dad hit me a lot when I was little, now I keep having flashbacks.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I lost my wallet and my credit card got stolen`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I think I might be asexual, I am 36 and have never had a crush.`],
  [
    '2022-12-30',
    '12:00:00',
    'Alexis',
    'user',
    `My uncle has to move in for three months and I am so angry I don't like him at all.`,
  ],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `Everything has gotten so hard since the pandemic.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I have long COVID and I still can't smell or taste.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `No one knows how much pain I am in.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I feel myself wasting away, I am not taking care of myself.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I gambled away $40,000 last night.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I am struggling with an alcohol addiction.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I caught my dad doing heroin`],
  [
    '2022-12-30',
    '12:00:00',
    'Alexis',
    'user',
    `I haven't talked to my sister in four years but her birthday is tomorrow`,
  ],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I lost my cat and cannot find her, she was my best friend.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I just hate my boyfriend's dog, it's so poorly trained and neglected.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I might quit my job and move to Mexico, I can't take it here anymore.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I just want to jump off a bridge.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My girlfriend spends so much money it's so stressful.`],
  [
    '2022-12-30',
    '12:00:00',
    'Alexis',
    'user',
    `I got hurt at a music festival but I can't remember exactly what happened.`,
  ],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My car got stolen and I don't know what to do.`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `My kids might have to move in again and I'm not ready for that`],
  ['2022-12-30', '12:00:00', 'Alexis', 'user', `I think I might have cancer, I don't know what to do.`],
];

function getMultipleRandom(arr, num) {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());

  return shuffled.slice(0, num);
}

const randomEntries = getMultipleRandom(entries, 5);
//
// randomEntries.forEach((entry) => {
//   const index = entries.indexOf(entry);
//
//   entries.splice(index, 1);
// });

const entriesWithMods = randomEntries.reduce((acc: any, entry: any) => {
  acc.push(entry);
  acc.push(['2022-12-30', '12:00:00', 'Moderator', 'moderator', '']);

  return acc;
}, []);

const messages = [
  ['time', 'screenName', 'role', 'msg', 'response time'],
  [
    '2019-08-23',
    '15:42:18',
    '----',
    '----',
    '----',
    '----',
  ],
  // ['2022-12-30', '12:00:00', 'Alexis', 'user', 'Alexis joined the chat', ''],
  ...entriesWithMods
];
//
// messages.splice(2, 1);

(window as any).csvData = messages;
