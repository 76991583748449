import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ChatRoomRealtimeService } from 'app/shared/services/chat-room-realtime/chat-room-realtime.service';
import { ChatParticipant } from 'app/shared/interfaces/room';

@Component({
  selector: 'app-chat-participants',
  templateUrl: './chat-participants.component.html',
  styleUrls: ['./chat-participants.component.scss'],
})
export class ChatParticipantsComponent implements OnInit, OnChanges {
  public userInfoBelowList: ChatParticipant;
  public moderatorInfoBelowList: ChatParticipant;
  public userInfoBelowTable: ChatParticipant;
  public moderatorInfoBelowTable: ChatParticipant;
  public showUsersTable = false;
  public panelCollapsed = false;
  public selectedUser: string
  // public typingUsers: Array<string> = [];
  public isModerator = true;
  public readonly smallTableSize = 8;
  public showKickBar = false;
  public thought: {
    text: string;
    userName: string;
    color: string;
  };

  private readonly colors = [
    '#70846d',
    '#e4873c',
    '#c8b833',
    '#d94238',
    '#5c9ed5',
    '#7470ad',
    '#b18059',
    '#7c7b8c',
    '#87a55a',
    '#ad5b57',
    '#6b8eaa',
  ];

  @Input() roomId: string;
  @Input() typingUsers: Array<string>;
  @Input() usersList: Array<string> = [];

  constructor(
    public chat: ChatRoomRealtimeService,
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // const {
    //   usersList: { currentValue: usersList },
    // } = changes;
    //
    // this.typingUsers = usersList
    //   .filter(user => !user.me && user.active && user.isChatting)
    //   .map(user => user.screenName);
    //
    // if (this.selectedUser) {
    //   this.selectedUser = usersList.find(user => user.roomUserId === this.selectedUser.roomUserId);
    // }
  }

  getUserColor(user: string): string {
    return this.colors[this.usersList.indexOf(user)];
  }

  toggleTopBar() {
    if (this.panelCollapsed) {
      this.resetSelectedUsers();

      if (this.showUsersTable) {
        this.showUsersTable = false;
      }
    } else {
      this.showUsersTable = true;
    }

    this.panelCollapsed = !this.panelCollapsed;
  }

  toggleUserOptionsBar(user: string) {
    this.selectedUser = this.selectedUser && this.selectedUser === user ? null : user;
  }

  toggleMute() {
    this.chat.toggleMute(this.selectedUser);
  }

  async kickOut() {
    await this.chat.kickOut(this.selectedUser);
    this.showKickBar = false;
    this.selectedUser = null;
  }

  toggleKickout() {
    this.showKickBar = !this.showKickBar;
  }

  async showEmergencyPage() {
    await this.chat.showEmergencyPage(this.selectedUser);
    this.showKickBar = false;
    this.selectedUser = null;
  }

  resetSelectedUsers() {
    this.userInfoBelowList = null;
    this.moderatorInfoBelowList = null;
    this.userInfoBelowTable = null;
    this.moderatorInfoBelowTable = null;
  }

  handleUserClick = (user: string, viewUserList = true) => {
    // // prop name contains field name which contains user object
    // let propName;
    //
    // // viewUserList means that user did action via user list (not via table)
    // if (viewUserList) {
    //   // check if current user is moderator
    //   if (this.isModerator) {
    //     // if moderator selects not yourself, show actions for user
    //     if (!user.me) {
    //       this.toggleUserOptionsBar(user);
    //     }
    //   } else {
    //     // propName = user.isModerator ? 'moderatorInfoBelowList' : 'userInfoBelowList';
    //   }
    // } else {
    //   // propName = user.isModerator ? 'moderatorInfoBelowTable' : 'userInfoBelowTable';
    // }
    //
    // // copy prev selected user into variable to compare it further and call reset method
    // const prevSelectedUser = this[propName];
    // this.resetSelectedUsers();
    //
    // if (propName && prevSelectedUser !== user) {
    //   this[propName] = user;
    //   this.panelCollapsed = true;
    // } else {
    //   this.panelCollapsed = false;
    // }
  };
}
