// The SharedModule is where any shared components, pipes/filters and services should go.
// The SharedModule can be imported in any other module when those items will be re-used.
// The shared module shouldn’t have any dependency to the rest of the application and should
// therefore not rely on any other module.
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
// import { ShareModule } from '@ngx-share/core';

import { HeaderComponent } from './components/header/header.component';
import { ChatParticipantsComponent } from './components/chat-participants/chat-participants.component';
import { TermsModalComponent } from './components/terms-modal/terms-modal.component';
import { ChatUserThoughtComponent } from './components/chat-user-thought/chat-user-thought.component';
import { ChatModeratorInfoComponent } from './components/chat-moderator-info/chat-moderator-info.component';

@NgModule({
  declarations: [
    ChatParticipantsComponent,
    HeaderComponent,
    TermsModalComponent,
    ChatUserThoughtComponent,
    ChatModeratorInfoComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, NgbModule, RouterModule],
  exports: [
    ChatParticipantsComponent,
    HeaderComponent,
    TermsModalComponent,
    ChatUserThoughtComponent,
    ChatModeratorInfoComponent,
  ],
})
export class SharedModule {}
