import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Store {
  public state$: Observable<any>;
  public stateSubject: BehaviorSubject<any>;

  protected constructor() {
    this.stateSubject = new BehaviorSubject({});
    this.state$ = this.stateSubject.asObservable();
  }

  get state(): any {
    return this.stateSubject.getValue();
  }

  setState(nextState: any): void {
    this.stateSubject.next(nextState);
  }
}
