import { Component, Input, OnInit } from '@angular/core';
import { ChatParticipant } from 'app/shared/interfaces/room';

@Component({
  selector: 'app-chat-moderator-info',
  templateUrl: './chat-moderator-info.component.html',
  styleUrls: ['./chat-moderator-info.component.scss'],
})
export class ChatModeratorInfoComponent implements OnInit {
  @Input() chatParticipant: ChatParticipant;

  constructor() {}

  ngOnInit() {
  }
}
