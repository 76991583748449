import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  public auth = firebase.auth;
  public firestore = firebase.firestore;
}
