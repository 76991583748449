import { Component } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Store } from 'app/core/services/store/store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  form = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(private fb: FormBuilder, private store: Store) {}

  handleSubmit() {
    const user = this.form.getRawValue();

    (window as any).currentUser = {
      ID: `id-${Math.floor(Math.random() * 10)}`,
      user_login: user.email,
      display_name: user.firstName,
    };

    this.store.setState(user);
  }
}
