import baseConfig from './environment.common';

export const environment = {
  ...baseConfig,
  production: true,
  stripePublishableKey: 'pk_test_VczYenDFe4R4bwXqet99x2Km',
  moderatorIdleTime: 1000 * 30, // 30 seconds
  apiUrl: 'https://www.supportiv-stageapi.com',
  firebase: {
    apiKey: 'AIzaSyC2H9GtR417qYHjTZHRnpg4jRdeMFu369Q',
    authDomain: 'supportiv-dev-a28a8.firebaseapp.com',
    databaseURL: 'https://supportiv-dev-a28a8.firebaseio.com',
    projectId: 'supportiv-dev-a28a8',
    storageBucket: 'supportiv-dev-a28a8.appspot.com',
    messagingSenderId: '374962532388',
  },
  mail: {
    info: 'stasmorgun4@gmail.com, stason4ik20@gmail.com, andrii.blyzno@gmail.com',
    moderator: 'stasmorgun4@gmail.com, stason4ik20@gmail.com, andrii.blyzno@gmail.com',
    chatModerator: 'stasmorgun4@gmail.com, stason4ik20@gmail.com, andrii.blyzno@gmail.com'
  },
  sendmailUrl: '/sendmail',
  middleEndApiUrl: '/api',
  adminEmail: 'admin@supportiv.com',
  paypalPublishableKey: 'AZDxjDScFpQtjWTOUtWKbyN_bDt4OgqaF4eYXlewfBP4-8aqX3PiV8e1GWU6liB2CUXlkA59kJXE7M6R',
  mixpanelToken: '',
  googleAnalyticsKey: '',
  sentryClientDSN: 'https://af690acb4e6f40da8e360480fc5dfaee@sentry.io/1413948',
  serverUrl: 'https://supportiv-stage.com',
  review: 'https://www.supportiv-stageapi.com/v2/dashboard/messages?roomid=',
  amazon: {
    sandbox: true,
    sellerid: 'A3PSTCA6FHHJW5',
    clientid: 'amzn1.application-oa2-client.e6ca0b2814e84d77ba76efc344a5175e',
  },
};
