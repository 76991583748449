import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChatComponent } from 'app/modules/chat/chat.component';

@NgModule({
  declarations: [ChatComponent],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
  exports: [ChatComponent],
})
export class ChatModule {}
