import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

export const APP_ERROR_PROVIDER = {
  provide: AppErrorHandler,
  useClass: AppErrorHandler,
};
