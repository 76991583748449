import { Component, Input, OnInit } from '@angular/core';
import { ChatParticipant } from 'app/shared/interfaces/room';

@Component({
  selector: 'app-chat-user-thought',
  templateUrl: './chat-user-thought.component.html',
  styleUrls: ['./chat-user-thought.component.scss'],
})
export class ChatUserThoughtComponent implements OnInit {
  @Input() chatParticipant: ChatParticipant;

  ngOnInit() {
  }
}
